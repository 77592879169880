$(document).ready(function (e) {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
    // var hash = window.location.hash;
    // $('.flat-tabs a[href="' + hash + '"]').tab('show');
});
// // override jquery validate plugin defaults
$.validator.setDefaults({
    highlight: function (element) {
        // $(element).closest('.form-group').addClass('has-error');
        $(element).addClass('is-invalid');
    },
    unhighlight: function (element) {
        $(element).removeClass('is-invalid');
        // $(element).closest('.form-group').removeClass('has-error');
    },
    // errorElement: 'span',
    // errorClass: 'help-block',
    errorPlacement: function (error, element) {
        // element.addClass('is-invalid');
        if (element.parent('.input-group').length) {
            error.insertBefore(element.parent());
        } else {
            error.insertBefore(element);
        }
    }
});
$(".form-default-validate").validate();
$('#flash-overlay-modal').modal();
$('div.alert').not('.alert-important').delay(3000).fadeOut(350);
$(".select2").select2({width: "100%"});
$(document).ajaxStart(function () {
    Pace.restart();
});
$('[data-mask]').inputmask();
//
// $('.flat-tabs a').click(function (e) {
//     e.preventDefault();
//     $(this).tab('show');
// });
// $("ul.nav-tabs > li > a").on("shown.bs.tab", function (e) {
//     var id = $(e.target).attr("href").substr(1);
//     window.location.hash = id;
// });
var lfm = function (options, cb) {

    var route_prefix = (options && options.prefix) ? options.prefix : '/laravel-filemanager';

    window.open(route_prefix + '?type=' + options.type || 'file', 'FileManager', 'width=900,height=600');
    window.SetUrl = cb;
};

$.fn.dataTable.ext.buttons.delview = {
    className: "buttons-delview",
    text: function (dt) {
        return '<i class="fa fa-trash"></i> ' + dt.i18n("buttons.delview", "DelView")
    },

    action: function (e, dt, button, config) {
        filters.is_softDeletes = !filters.is_softDeletes;
        dt.draw()
    }
};

var filters = {is_softDeletes: !1};

$.fn.dataTable.ext.buttons.reload = {
    text: 'Reload',
    action: function (e, dt, node, config) {
        dt.ajax.reload();
    }
};

function doPoll() {
    $.getJSON('/ajax/polling', function (response) {
        if (response.success) {
            var num_email = response.data[0];
            $("#label-num_email").html(num_email);
        }
        setTimeout(doPoll, 30000);
    });
};
doPoll();